@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.loading {
    cursor: progress !important;

    button {
        cursor: progress !important;
    }
}
